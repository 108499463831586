import React from 'react';
import AccountView from './Account';

const Account = () => {
  return (
    <main>
      <AccountView />
    </main>
  );
};

export default Account;
